$(document).ready( function(){
    $('#category1').trigger('click');
});

$('.category_button').click(function () {
    // 一回スタイルを戻す
    $('.category_button').removeAttr('style')

    // クリック時のスタイルを適用する
    activateCSSWhenClick($(this))

    // 一度全て課題感を非表示
    $('.issue_button').hide();
    issueReset();

    // カテゴリに一致した課題感の表示
    let category_id = $(this).attr('id');

    $('.category_issue_sub_box_content_issue').children('button').each(function (index, element) {
        if($(element).attr('class').indexOf(category_id) >= 0) {
            $(element).show();
        }
    });

    // 隠し項目にcategoryIdをセット
    $('#category').val($(this).val());
})

$('.issue_button').click(function () {

    issueReset()

    // クリック時のスタイルを適用する
    activateCSSWhenClick($(this))
});

function activateCSSWhenClick(el) {
    el.css({
        border: 'solid',
        borderColor: '#028B8B'
    });

    // 隠し項目にissueIdをセット
    $('#issue').val(el.val());
}

function issueReset() {
    // 一回スタイルを戻す
    $('.issue_button').css({
        border: '0px',
        color: 'black'
    });

    $('#issue').val('');
}
